import React from 'react'
import { Link } from 'react-router-dom'
const Contact = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                 <li><Link to="/"> Home</Link> </li>  
                 <li>Contact</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Contact  </h1>
                    </div>
                </div>
                <div className="contactusbox">
                <div className="contactusdiv fstcontact">
                      <div className="contactusimg">
                             <img src="https://webapi.entab.info/api/image/STFSR/public/Images/Location-icon.png" className="img-fluid" alt="" />
                             <h4>Location</h4>
                      </div>
                      <div className="contactusdesc">
                             <div className="contactusicn"><i className="bi bi-geo-alt-fill"></i></div>
                             <Link to="https://maps.app.goo.gl/ffVU59SrVPuVZQbp9" target="_blank">  St. Francis School  Harmu, Ranchi - 834002, Jharkhand    </Link>
                      </div>
                </div>
                <div className="contactusdiv scdcontact">
                <div className="contactusimg">
                      <img src="https://webapi.entab.info/api/image/STFSR/public/Images/timingicn.png" className="img-fluid" alt="" />
                       <h4>Timing</h4>
                </div>
                <div className="contactusdesc">
                     <div>
                          <div className="contactusicn">
                          <i className="bi bi-clock-fill"></i>
                     </div>
                         <p><strong>School Timings –</strong> 7:30 am to 1:40 pm </p>
                     </div> 
                     <div>
                          <div className="contactusicn">
                          <i className="bi bi-alarm-fill"></i>
                     </div>
                     <p><strong>Office Visiting Hours –</strong> 9:00 am to 01:00 pm </p>
                     </div>
                </div>
          </div>
                <div className="contactusdiv thrdcontact">
                      <div className="contactusimg">
                            <img src="https://webapi.entab.info/api/image/STFSR/public/Images/Phone-icon.png" className="img-fluid" alt="" />
                             <h4>Phone</h4>
                      </div>
                      <div className="contactusdesc">
                           <div>
                                <div className="contactusicn">
                                 <i className="bi bi-telephone-fill"></i>
                           </div>
                                <Link to="/tel:8987705291" target="_blank">8987705291</Link>
                           </div>
                           <div>
                                <div className="contactusicn">
                                 <i className="bi bi-printer-fill"></i>
                           </div>
                                <Link to="/tel:9472708434" target="_blank">9472708434</Link>
                           </div>
                           <div>
                                <div className="contactusicn">
                                <i className="bi bi-envelope-fill"></i>
                           </div>
                                <Link to="mailto:sfs_harmu@hotmail.com"> sfs_harmu@hotmail.com</Link>
                           </div>
                      </div>
                </div>
           </div>  
           <div className="contactiframe">
               <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30006347.03673884!2d85.297902!3d23.356211!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e05eccbfc44b%3A0x233a6e5ec3a984fc!2sSt%20Francis%20School!5e0!3m2!1sen!2sin!4v1722234467482!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           </div>
            </div>
        </div>
     </>
  )
}

export default Contact

