import React from 'react'
import { Link } from 'react-router-dom'
const Pphelp = () => {
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Portal (Help?)</li> 
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Our School  </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="erp_sec">  
                    <p class="guideheading">CampusCare®  Application Guidelines &amp; Instructions </p>
                    <div class="clr10"></div>
                    <p class="guideheading"><strong>How to LOG IN Parent Portal?</strong></p>
                    <div class="clr10"></div>
                    <p class="details"> 1. Open Chrome <img src="https://webapi.entab.info/api/image/STFSR/public/Images/chrome.png"  alt="School ERP Software, Mobile App, School Management Software"></img>  OR Mozilla 
                    <img src="https://webapi.entab.info/api/image/STFSR/public/Images/mzilla.png"   alt="School ERP Software, Mobile App, School Management Software"></img> or Internet 
                    <img src="https://webapi.entab.info/api/image/STFSR/public/Images/internet.png" width="18" height="20"  alt="School ERP Software, Mobile App, School Management Software"></img>  </p>
                    <p class="details"> 2. Write the school URL <strong><a target="_blank" href="https://sfsr.campussoft.net/">"sfsr.campussoft.net"</a></strong> in address bar.
                    (you will be routed to the Log In credential page).</p>
                        <p class="details"> 3. Then, enter the USER ID and Click on  "Next button", Then enter
                    PASSWORD and Click on Sign in  button.</p>
                        <p class="details">Once logged in, you are welcomed with various information
                    relating to assignments, news, circulars, date sheet, fee details etc. that
                    are self explanatory. Wish you to go through every links that are
                    provided and explore more.</p>
                    
                    <div class="clr10"></div>
                    <p class="guideheading"><strong>How to RESET PASSWORD in Parent Portal? </strong></p>
                    <div class="clr10"></div>
                    <p class="details"> 1. Open Chrome <img src="https://webapi.entab.info/api/image/STFSR/public/Images/chrome.png"  alt="School ERP Software, Mobile App, School Management Software"></img> OR Mozilla <img src="https://webapi.entab.info/api/image/STFSR/public/Images/mzilla.png"   alt="School ERP Software, Mobile App, School Management Software"></img> or Internet <img src="https://webapi.entab.info/api/image/STFSR/public/Images/internet.png" width="18" height="20"  alt="School ERP Software, Mobile App, School Management Software"></img>  </p>
                    <p class="details"> 2. Write the school URL <strong><a target="_blank" href="https://sfsr.campussoft.net/">"sfsr.campussoft.net"</a></strong> in address bar.
                    (you will be routed to the Log In credential page).</p>
                    <p class="details">3. Then, click on “Forgot User ID or Password?” text given below User ID field.</p>
                    <p class="details">4. Select the User Type (For which you want a password) then Enter Registered Mobile No. and click on “Reset” Button.</p>
                    <p class="details">5. You will get an SMS on your registered mobile no. along with login credentials. 
                    </p>
                              <div class="clr10"></div>
                              <div>
                    <p class="guideheading"><strong> How to Install &amp; operate MOBILE APP? </strong></p>
                            <p>1. Go to <img src="https://webapi.entab.info/api/image/STFSR/public/Images/playstoreicn.png" width="79" height="20"  alt="School ERP Software, Mobile App, School Management Software"></img> Store / <img src="https://webapi.entab.info/api/image/STFSR/public/Images/appleicn.png"  alt="School ERP Software, Mobile App, School Management Software"></img>  and Search 
                    
                    "CampusCare®". </p>
                            <p> 2. Install and open the <a href="https://www.entab.in/mobile-apps.html"><b>Mobile App</b></a>.</p>
                            <p>3. Enter school web portal URL: <strong><a target="_blank" href="https://sfsr.campussoft.net/">"sfsr.campussoft.net"</a></strong> in link bar      &amp; verify. </p>
                            <p>4. After verifying, you will get an option for User ID &amp; Password.</p>
                            <p>Once logged in, you are welcomed with various information relating to assignments, news. circulars, date sheet, fee details etc that are self Explanatory. Wish you to go through every links that are 
                    
                    provided and      explore more. </p>
                            <p><strong>NOTE* :</strong> Kindly update your <a href="https://www.entab.in/mobile-apps.html"><b>Mobile Apps</b></a> time to time to avoid difficulties during usage.</p>
                        </div>
                        <div class="clr10"></div>
                        <div>
                                  <p class="guideheading"><strong>How to make Online Payment? :-</strong> </p>
                                  <p> 1. Open Google Chrome <img src="https://webapi.entab.info/api/image/STFSR/public/Images/chrome.png"  alt="School ERP Software, Mobile App, School Management Software"></img> / Mozilla Firefox
                                   <img src="https://webapi.entab.info/api/image/STFSR/public/Images/mzilla.png"   alt="School ERP Software, Mobile App, School Management Software"></img> or Internet Explorer 
                                   <img src="https://webapi.entab.info/api/image/STFSR/public/Images/internet.png" width="18" height="20"  alt="School ERP Software, Mobile App, School Management Software"></img> </p>
                                  <p> 2. Enter the school URL <strong><a target="_blank" href="https://sfsr.campussoft.net/">"sfsr.campussoft.net"</a></strong> address bar and you will be routed to the Log In page.</p>
                                  <p> 3. Now, enter the USER ID and then enter your PASSWORD and Click on ‘Sign in’ button.</p>
                                  <p> 4. After login, click on the Online Payment menu.</p>
                                  <p> 5. Select the installment to pay &amp; click on the proceed button.</p>
                                  <p> 6. Now, you will be redirected to Payment Gateway page.</p>
                                  <p> 7. Choose the Pay mode : Credit Card/Debit Card/Net banking &amp; fill the details to complete the payment process.</p>
                                  <p> 8. After successful payment, you will receive the fee receipt &amp; SMS on successful fee payment.</p>
                                  <div class="clr25"></div>
                                  <p><b>Note :-</b><i> If for any reason, the fee payment output details are not displayed on your screen after you have made payments, please check the details in “My Payment” in Finance link. If your accounts get debited/deducted and receipt is not available, you are advised to contact school office or mail us.</i></p>
                              </div>
                    
                    
                              <div class="clr10"></div>
                    <div class="assistance">
                    <h4 class="guideheading">Please call or write for assistance:</h4>
                    <p class="details"><strong> <a href="https://www.entab.in/contact-us.html">PARENT HELP DESK</a></strong> ( 8 AM to 8:00 PM ) - <strong>Tele No.</strong> 011- 43193333 - Ext : 5,<strong> E-Mail :</strong> <strong><a href="mailto:parentdesk@entab.in" class="yellow-link"> parentdesk@entab.in</a></strong></p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
     </>
  )
}

export default Pphelp

